import useConstants from '~/composables/useConstants'
import useFrontDoorApi from '~/composables/useFrontDoorApi'
import { differenceInMinutes, format, isBefore, isSameDay } from 'date-fns'
import pluralize from 'pluralize'

import CourseArea = models.server.api.sitecore.courseArea.CourseArea
import SubjectGroup = models.server.api.sitecore.courseArea.SubjectGroup
import Course = models.server.api.sitecore.course
import Offering = models.server.api.curriculum.offering.Offering
import { useAuthState } from '~/composables/auth/useAuth'

export default function () {
  const { EXPIRY_IN_MINUTES } = useConstants()

  const getImgPath = (str: string | undefined) => {
    // The part of the string after 'Images/' is the unique path to the asset
    // str may return empty string | undefined | null
    if (typeof str === 'undefined' || stringEmpty(str)) {
      return ''
    }

    const searchStr = 'Images/'
    const searchIndex = str.indexOf(searchStr)

    return searchIndex !== -1
      ? str.substring(searchIndex + searchStr.length, str.length)
      : ''
  }

  const getImgSrc = (str?: string) => {
    if (stringEmpty(str)) {
      return ''
    }

    // The part of the string after 'Images/' is the unique path to the asset
    const searchStr = 'Images/'
    const path = str?.substring(
      str.indexOf(searchStr) + searchStr.length,
      str.length
    )

    const { getImgUrl } = useFrontDoorApi()
    return path && !stringEmpty(path) ? getImgUrl(path) : ''
  }

  const paginationRange = (current: number, perPage: number) => {
    const offset = (current - 1) * perPage
    const limit = offset + perPage
    return {
      offset,
      limit
    }
  }

  // renamed createList to joinList and simplified method
  const joinList = (items: string[], conj = 'and') => {
    if (!items || !Array.isArray(items) || !items.length) return ''

    if (items.length === 1) return items[0]

    const lastItemIdx = items.length - 1
    const base = items.slice(0, lastItemIdx).join(', ')
    return `${base} ${conj} ${items[lastItemIdx]}`
  }

  const getProductUrl = ({
    courseArea,
    subjectGroup,
    course,
    offering
  }: {
    courseArea?: CourseArea | Course.CourseArea
    subjectGroup?: SubjectGroup
    course?: Course.Course
    offering?: Offering
  }) => {
    // @ts-ignore
    const { $slugify }: { $slugify: (string) => string } = useNuxtApp()

    if (!courseArea && course && course.courseArea) {
      courseArea = course.courseArea
    }

    let caSlug = ''
    let courseSlug = ''
    let offeringSlug = ''

    if (courseArea) {
      const caName = $slugify(courseArea.name || '')
      caSlug = `/course-areas/${courseArea.slug || caName}`
    }

    if (courseArea && subjectGroup) {
      const caName = $slugify(courseArea.name || '')
      caSlug = `/course-areas/${courseArea.slug || caName}`
      let sgSlug = subjectGroup.slug
      if (!sgSlug) {
        sgSlug = $slugify(subjectGroup.name || '')
      }
      return `${caSlug}#${sgSlug}`
    }

    if (caSlug && course) {
      const courseTitle = $slugify(course.title || '')
      courseSlug = `/courses/${courseTitle}--${course.id}`
    }

    if (courseSlug && offering) {
      offeringSlug = `/offerings/${offering.id}`
    }

    return `${caSlug}${courseSlug}${offeringSlug}`
  }

  /* Formatting */
  const dateMapping = {
    week: 7,
    month: 30.4,
    year: 365.25
  }

  /**
   * Format days number to be a human readable duration
   * @param {number} days - A number of days
   */
  const formatDuration = (days: number | null | undefined) => {
    if (!days || isNaN(days)) {
      return
    }
    if (days < 14) {
      return pluralize('day', days, true)
    } else if (days < 85) {
      return pluralize('week', Math.round(days / dateMapping.week), true)
    } else if (days <= 365) {
      return pluralize('month', Math.round(days / dateMapping.month), true)
    } else {
      let years = Math.floor(days / dateMapping.year)
      let months = Math.round((days % dateMapping.year) / dateMapping.month)

      if (months === 12) {
        years += 1
        months = 0
      }

      return months > 0
        ? `${pluralize('year', years, true)} ${pluralize(
            'month',
            months,
            true
          )}`
        : pluralize('year', years, true)
    }
  }

  /**
   * Pushes analytics data to GTM
   * @param {string} eventName - Name to be passed into a GTM event
   * @param {object} data - Keys and values to be passed into a GTM event
   */
  const sendGtmData = (eventName: string, data: object) => {
    if (!window) {
      console.error('This function must be called from the client')
      return
    }

    if (window.dataLayer && Array.isArray(window.dataLayer)) {
      window.dataLayer.push({ ...data, event: eventName })
    } else {
      window.dataLayer = [{ ...data, event: eventName }]
    }
  }

  /**
   * Checks whether to override the start and end date's of an offering
   * @param {object} offering - A course offering
   */
  const checkDateOverride = (offering: Offering | undefined) => {
    const { isEnrolAnytime, classStartDate, classEndDate } = offering || {}

    if (isEnrolAnytime) {
      return { startDate: 'Any time', endDate: null }
    }

    // Otherwise, return formatted versions of the original start and end dates
    return {
      startDate: formatDate(classStartDate, 'do LLL yyy'),
      endDate: formatDate(classEndDate, 'do LLL yyy')
    }
  }

  const getCampaign = (id: string | undefined) => {
    const pathway = id && id.toLowerCase().includes('tprpl')
    return pathway ? 'tradepathways' : 'e2e'
  }

  const setCampaign = (id: string) => {
    const tprpl = id.toLowerCase().includes('tprpl')
    return tprpl ? 'tradepathways' : 'e2e'
  }

  /**
   * Format string to appear as AUD currency
   * @param {string|number|undefined} val - A status type
   */
  const formatCurrency = (val: string | number | undefined) => {
    if (val !== 0 && !val) return

    const num = typeof val === 'number' ? val : parseInt(val)

    return num.toLocaleString('en-AU', {
      style: 'currency',
      currency: 'AUD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    })
  }

  const tryParseInt = (value: unknown, radix = 10) => {
    if (!value || isNaN(value as number)) return { isValid: false, value: 0 }

    // radix can be invalid and result in NaN
    const result = parseInt(value as string, radix)
    return isNaN(result)
      ? { isValid: false, value: 0 }
      : { isValid: true, value: result }
  }

  const isExpired = (
    targetISODate?: string,
    expiryInMinutes = EXPIRY_IN_MINUTES
  ) => {
    // no date to check, nothing to expire
    if (!targetISODate) return false

    const targetDate = new Date(targetISODate)
    return differenceInMinutes(new Date(), targetDate) > expiryInMinutes
  }

  /**
   * A look-up function to set the right UI information based on an application's status
   * @param {string} status - application status
   */
  // TODO: move/update this after migration completed.
  const getApplicationStatusInfo = (status: string) => {
    switch (status?.toLowerCase()) {
      case 'draft':
        return {
          label: 'Draft',
          colour: 'web-purple',
          borderColor: 'purple',
          ctaContent:
            'Your application has not been submitted. Please submit before the course start date.',
          ctaLabel: 'Continue'
        }
      case 'submitted':
        return {
          label: 'Submitted',
          colour: 'web-blue',
          borderColor: 'blue',
          ctaContent:
            'Your application has been successfully submitted and can no longer be edited.'
        }
      case 'declined':
        return {
          label: 'Declined',
          colour: 'web-orange',
          borderColor: 'orange',
          ctaContent: 'Your application for this course has been declined.'
        }
      case 'in-assessment':
        return {
          label: 'In assessment',
          colour: 'web-red',
          borderColor: 'red',
          ctaContent:
            'Your application is being reviewed. You will be notified of the outcome via email.'
        }
      case 'requires-attention':
        return {
          label: 'Requires attention',
          colour: 'web-red',
          borderColor: 'red',
          ctaContent:
            'You need to provide further evidence to continue with this application.',
          ctaLabel: 'Provide evidence'
        }
      case 'place-offered':
        return {
          label: 'Place offered',
          colour: 'web-green',
          borderColor: 'green',
          ctaContent:
            'Congratulations! You have been offered a place in this course. Please review and accept or reject your place before the course start date.',
          ctaLabel: 'Review offer'
        }
      case 'enrolled':
        return {
          label: 'Enrolled',
          colour: 'web-green',
          borderColor: 'green',
          ctaContent:
            'You are enrolled in this course. You can now log in to your learning space.',
          ctaLabel: 'Log in'
        }
      case 'offer-expired':
        return {
          label: 'Offer expired',
          colour: 'web-orange',
          borderColor: 'orange',
          ctaContent:
            'Your offer has expired, you can no longer access this application.',
          ctaLabel: ''
        }
      case 'offer-accepted':
        return {
          label: 'Offer accepted',
          colour: 'web-green',
          borderColor: 'green',
          ctaContent: 'You have accepted the offer for this course.',
          ctaLabel: ''
        }
      case 'offer-rejected':
        return {
          label: 'Offer rejected',
          colour: 'web-red',
          borderColor: 'red',
          ctaContent:
            'This course offer has been rejected. Please contact Student Services if there is an error.',
          ctaLabel: ''
        }
      case 'withdrawn':
        return {
          label: 'Withdrawn',
          colour: 'web-orange',
          borderColor: 'orange',
          ctaContent: 'Your application has been withdrawn.',
          ctaLabel: ''
        }
      case 'waitlisted':
        return {
          label: 'Waitlisted',
          colour: 'web-orange',
          borderColor: 'orange',
          ctaContent:
            'Your application has been successful and is on the waiting list for this course. You will be offered a place if one becomes available',
          ctaLabel: ''
        }
      case 'not-eligible':
        return {
          label: 'Not eligible',
          colour: 'web-orange',
          borderColor: 'orange',
          ctaContent:
            'The application has not met the minimum requirements to be submitted',
          ctaLabel: ''
        }
      case 'expired':
        return {
          label: 'Expired',
          colour: 'web-orange',
          borderColor: 'orange',
          ctaContent:
            'Your application draft cannot be progressed as we are no longer accepting applications.',
          ctaLabel: ''
        }
      case 'completed':
        return {
          label: 'Completed',
          colour: 'web-green',
          borderColor: 'green',
          ctaContent: '',
          ctaLabel: ''
        }
      case 'course-full':
        return {
          label: 'Course full',
          colour: 'web-orange',
          borderColor: 'orange',
          ctaContent:
            'This course is now full. Please return to the course page and enrol to another offering.',
          ctaLabel: 'Return'
        }
      case 'applications-closed':
        return {
          label: 'Applications full',
          colour: 'web-orange',
          borderColor: 'orange',
          ctaContent:
            'Applications are full for this option, but you may be able to apply for this course with another location, start date or delivery. Please return to the course for available options.',
          ctaLabel: 'Return'
        }
      default:
        return {
          label: 'Unknown',
          colour: 'web-blue',
          borderColor: 'blue',
          ctaContent: ''
        }
    }
  }

  const isSameOrBefore = (target?: string | Date, base?: Date) => {
    if (!target || !base) return false

    const picked = typeof target === 'string' ? new Date(target) : target
    return isBefore(picked, base) || isSameDay(picked, base)
  }

  const stringEmpty = (value?: string | null) => !(value && value.trim().length)

  const resolveValue = (path: string | string[], obj = {}, separator = '.') => {
    // If path is already an array use it,
    // Otherwise split the path into an array using the passed in separator param
    const properties: string[] = Array.isArray(path)
      ? path
      : path.split(separator)

    // Iterate through object properties until inner mosted nested property is reached
    return properties.reduce((prev: any, curr) => {
      return prev && prev[curr]
    }, obj)
  }

  /**
   * Replaces placeholders, marked using {<field>}, in a string with values from a replacement object
   * @param value String containing placeholders, e.g. 'Hello {userName}'
   * @param replacements Object containing replacement values, e.g. { userName: 'John' }
   * @returns String with placeholders replaced with values, e.g. 'Hello John'
   */
  const formatString = (
    value?: string,
    replacements: Record<string, unknown> = {}
  ): string | undefined => {
    if (stringEmpty(value)) return

    return value?.replace(/{(\w+)}/g, (match: string, field: string) =>
      Object.hasOwn(replacements, field)
        ? (replacements[field] as string)
        : match
    )
  }

  const formatDate = (stringDate: string | undefined, stringFormat: string) =>
    stringDate ? format(new Date(stringDate), stringFormat) : undefined

  const sendApplicablePageGtmData = async () => {
    const { isAuthenticated, user } = await useAuthState()
    const { gtag } = useGtag()
    const { isIos, isAndroid } = useDevice()

    gtag('event', 'setAttributes', {
      loggedIn: isAuthenticated.value,
      platformCategory: isIos ? 'iOS' : isAndroid ? 'Android' : 'Web'
    })
  }

  const substrBeforeLast = (str?: string, char = '-') => {
    return !!str ? str.slice(0, str.lastIndexOf(char)) : undefined
  }

  return {
    dateMapping,
    getImgPath,
    getImgSrc,
    paginationRange,
    joinList,
    stringEmpty,
    resolveValue,
    getProductUrl,
    formatDuration,
    sendGtmData,
    checkDateOverride,
    getCampaign,
    setCampaign,
    formatCurrency,
    formatString,
    tryParseInt,
    isExpired,
    getApplicationStatusInfo,
    isSameOrBefore,
    sendApplicablePageGtmData,
    substrBeforeLast
  }
}
